<template>
  <div v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'">
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
           
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Lista
                  <span id="txtDashboardNomePlataforma">Itens</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <!-- fd-app-content -->
              <section
                class="fd-app-content"
              >
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm">
                    <thead class="thead-dark">
                      <tr>
                        <th class="text-center"><small class="font-weight-bold">Título da aula</small></th>
                        <th class="text-center"><small class="font-weight-bold">Nome do curso</small></th>
                        <th class="text-center">
                          <small class="font-weight-bold">Referecia</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="lista in fastAdminLista"
                        :key="lista.id_aula"
                      >
                        <td class="align-middle text-center">
                          {{ lista.titulo_aula }}
                        </td>  
                        <td class="align-middle text-center">
                          {{ lista.nome_curso }}                                 
                        </td>  
                        <td class="align-middle text-center">
                          <a
                            class="btn btn-primary btn-sm mt-2 ml-2"
                            :href="lista.referencia_atividade"
                            target="_blank"
                            download
                          >
                            <small>
                              <i
                                class="fa fa-eye"
                                aria-hidden="true"
                              />
                              &nbsp;Visualizar
                            </small>
                          </a>                              
                        </td>   
                      </tr>
                    </tbody>
            
                  </table>
                </div>
              </section>
              <!-- fd-app-capacitacoes -->
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
//import ImageSiteFormEffect01 from "@/assets/images/form-effect-01.png";

export default {
  name: "HomeInternoAdminPagProvisoriaAustran",
  mixins: [methods],
  data: function () {
    return {
      fastAdminLista: [],
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.getListaProvisoria();
          this.$store.state.fastCarregando = false;         
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getListaProvisoria(){
      this.promiseGetFastApi("api/fast_curso_aula_atividade/lista_tipo", "id_plataforma=" + 2486 + "&nome_curso=" + "EJA" + "&tipo_atividade=" + "RF")
      .then((res) => {
        console.log("getCursoItemCobranca", res)
        this.fastAdminLista = res;
        console.log("", this.fastAdminLista)
      })
      .catch(e => {
        this.exibeToasty(e, "error")
      })
    },
  },
};
</script>

<style></style>
